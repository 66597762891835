import {Injectable, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../_config/environments/environment';

@Injectable()
export class DataProviderService implements OnInit {

  constructor(private http: HttpClient) { }

  ngOnInit() {
  }

}
