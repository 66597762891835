import { Component } from '@angular/core';
import { Title} from '@angular/platform-browser';

@Component({
  moduleId: module.id,
  selector: 'app-root',
  templateUrl: 'templates/app.component.html'
})

export class AppComponent {
  title = 'Content Management Systems (SDL Tridion Sites) and IT Services Provider | Tiger Systems, Inc.';
  public constructor(private titleService: Title) {
    this.setTitle(this.title);
  }
  setTitle(newTitle: string) {
    this.titleService.setTitle(newTitle);
  }
}
