import {NgModule} from '@angular/core';

import { AppComponent } from './_startup/components/app/app.component';
import {LocationStrategy, PathLocationStrategy} from '@angular/common';
import { AppStartModule } from './_startup/components/app/app.start.module';

@NgModule({
  declarations: [
  ],
  imports: [
    AppStartModule
  ],
  providers: [{provide: LocationStrategy, useClass: PathLocationStrategy}],
  bootstrap: [AppComponent]
})
export class AppModule {
}
