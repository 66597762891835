<div class="container service-container-margin">
  <div class="row">
    <div class="col-md-12">
      <div class="flip-card">
        <div class="flip-card-inner">
          <div class="flip-card-front">
            <h4 class="card-title">
              Content Management Consulting &amp; Implemenation
            </h4>
            <i class="fas fa-cloud faCustomStyles"></i><br />
            <i class="fas fa-mobile-alt faCustomStyles"></i><br />
            <i class="fas fa-pencil-ruler faCustomStyles"></i>
          </div>
          <div class="flip-card">
            <div class="flip-card-inner">
              <div class="flip-card-front">
                <i class="far fa-edit faCustomStyles"></i>
                <h4 class="card-title"> Content Management</h4>
              </div>
              <div class="flip-card-back">
                <div class="card">
                  <div class="card-body">
                    <p class="card-text">
                      We provide services for open source and enterprise Content Management Systems (CMS),
                      such as SDL Tridion Sites, Adobe Experience, Sitecore, Umbraco, Word Press, and Joomla.<br /><br />
                      We bring vast expertise to integrate search servers (Solr, Elastic, Azure) providing search capabilities across portals.
                      We have extensive knowledge and resources for performing content migrations across CMS, platforms, and languages.
                      <a href="/services/content-management-systems" class="btn btn-link btn-sm">read more...</a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="flip-card">
            <div class="flip-card-inner">
              <div class="flip-card-front">
                <img src="/content/en-global/images/sdl-orange-logo.png" style="height:75px; margin-top: 18%;" />
                <h4 class="card-title">SDL Tridion Sites</h4>
              </div>
              <div class="flip-card-back">
                <div class="card">
                  <div class="card-body">
                    <p class="card-text">
                      We provide SDL expert services for Digital Content Strategy, Search Integration, Translations,
                      implementations such as Templating, GUI Extensions, Workflow, Event System, and
                      Content Delivery solutions with DXA, DD4T, CWA, SCD, and custom MVC in .NET or JAVA.<br /><br />
                      We support content migrations, new installations, and existing infrastructure of SDL
                      Tridion Sites in on-premises and cloud-hosted environments.
                      <a href="/services/sdl-tridion-sites" class="btn btn-link btn-sm">read more...</a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
