import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-services-sdl-tridion-sites',
  templateUrl: './templates/content-management.component.html',
  styleUrls: ['./templates/content-management.component.css']
})
export class ContentManagementComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }
}
