<div class="container service-container-margin">
  <div class="row">
    <div class="col-md-12">
      <div class="flip-card">
        <div class="flip-card-inner">
          <div class="flip-card-front">
            <i class="fas fa-search faCustomStyles"></i>
            <h4 class="card-title">Search Integration</h4>
          </div>
          <div class="flip-card-back">
            <div class="card">
              <div class="card-body">
                <p class="card-text">
                  "How about just a search box in the page header" — sounds familiar? That's how it starts and morphs into
                  something awesome that works across sites! We can develop custom solutions using Push or crawl,
                  Secured, Faceted, Server and Client solutions.<br /><br />

                  Let's discuss the custom search for your business solutions. We integrate
                  Solr, Elastic, Azure, and custom search engines with your existing or new applications.
                  <a href="/services/translation-platforms" class="btn btn-link btn-sm">read more...</a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
