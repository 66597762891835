<footer>
<div class="container">
  <div class="row">
    <div class="col-md-4">
      <h2 class="heading-title"><i class="fa fa-envelope"></i> Contact Us</h2>
      <p><i class="fa fa-home"></i>
        &nbsp;1684 Decoto Rd.<br>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        Suite 325 <br>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        Union City, CA 94587<br>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        USA
      </p>
      <!--<p><i class="fa fa-phone"></i> &nbsp;+1 (415) 967-0052</p>-->
      <!--<p><i class="fa fa-envelope"></i> &nbsp;<a href="mailto:info@tigsys.com">info@tigsys.com</a></p>-->
      <!--<ul class="list-inline">-->
        <!--<li class="list-inline-item"><a [ngClass]="{footerSocialIcon: true}" href="{{facebookLink}}" target="_blank"><i-->
          <!--class="fa fa-facebook-square"></i></a></li>-->
        <!--<li class="list-inline-item"><a [ngClass]="{footerSocialIcon: true}" href="{{linkedInLink}}" target="_blank"><i-->
          <!--class="fa fa-linkedin-square"></i></a></li>-->
        <!--<li class="list-inline-item"><a [ngClass]="{footerSocialIcon: true}" href="{{twitterLink}}" target="_blank"><i-->
          <!--class="fa fa-twitter-square"></i></a></li>-->
      <!--</ul>-->
    </div>
    <div class="col-md-4">
      <!--<h2 class="heading-title"><i class="fa fa-twitter"></i> Latest Tweets</h2>-->
      <!--<ul class="list-unstyled">-->
        <!--<li><a target="_blank" href="#"><i-->
          <!--class="fa fa-twitter"></i> Coming soon...</a></li>-->
      <!--</ul>-->
      <h2 class="heading-title"><i class="fa fa-phone"></i> Phone</h2>
      <p><i class="fa fa-phone"></i> &nbsp;+1 (415) 967-0052</p>
    </div>
    <div class="col-md-4">
      <!--<h2 class="heading-title"><i class="fa fa-newspaper-o"></i> Latest Posts</h2>-->
      <!--<ul class="list-unstyled">-->
        <!--<li><a target="_blank" href="#"><i-->
          <!--class="fa fa-pencil"></i>Coming soon...</a></li>-->
      <!--</ul>-->
      <h2 class="heading-title"><i class="fa fa-envelope"></i> Email</h2>
      <p><i class="fa fa-envelope"></i>&nbsp;<a href="mailto:info@tigsys.com">info@tigsys.com</a></p>
    </div>
  </div>
</div>
</footer>
<div class="container">
<div class="row">
  <div class="col-md-12">
    <p class="text-center">Copyright <i class="fa fa-copyright"></i> 2012-2020 <strong style="color:#ff6600">Tiger Systems, Inc.</strong> All rights reserved </p>
    <!--| <a href="#">Privacy Policy</a> | <a href="#">Terms&Conditions</a>-->
  </div>
</div>
</div>

