/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./templates/carousel.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "ng2-device-detector";
import * as i4 from "./carousel.component";
var styles_CarouselComponent = [i0.styles];
var RenderType_CarouselComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CarouselComponent, data: {} });
export { RenderType_CarouselComponent as RenderType_CarouselComponent };
function View_CarouselComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [], [[8, "className", 0]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "img", [["class", "d-block img-fluid"]], [[8, "src", 4], [8, "alt", 0]], null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 6, "div", [["class", "carousel-caption d-none d-md-block"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "h3", [], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), (_l()(), i1.ɵeld(5, 0, null, null, 3, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", " "])), (_l()(), i1.ɵeld(7, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵted(8, null, [" ", " "]))], null, function (_ck, _v) { var currVal_0 = i1.ɵinlineInterpolate(1, "carousel-item ", _v.context.$implicit.cls, ""); _ck(_v, 0, 0, currVal_0); var currVal_1 = i1.ɵinlineInterpolate(1, "", _v.context.$implicit.name, ""); var currVal_2 = i1.ɵinlineInterpolate(1, "", _v.context.$implicit.title, ""); _ck(_v, 1, 0, currVal_1, currVal_2); var currVal_3 = _v.context.$implicit.captionTitle; _ck(_v, 4, 0, currVal_3); var currVal_4 = _v.context.$implicit.captionText; _ck(_v, 6, 0, currVal_4); var currVal_5 = _v.context.$implicit.captionTextLineTwo; _ck(_v, 8, 0, currVal_5); }); }
export function View_CarouselComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "div", [["class", "container carousel-top-margin"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 11, "div", [["class", "carousel slide"], ["data-ride", "carousel"], ["id", "carouselIndicators"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "carousel-inner"], ["role", "listbox"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CarouselComponent_1)), i1.ɵdid(4, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 3, "a", [["class", "carousel-control-prev"], ["data-slide", "prev"], ["href", "#carouselIndicators"], ["role", "button"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 0, "span", [["aria-hidden", "true"], ["class", "carousel-control-prev-icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "span", [["class", "sr-only"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Previous"])), (_l()(), i1.ɵeld(9, 0, null, null, 3, "a", [["class", "carousel-control-next"], ["data-slide", "next"], ["href", "#carouselIndicators"], ["role", "button"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 0, "span", [["aria-hidden", "true"], ["class", "carousel-control-next-icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 1, "span", [["class", "sr-only"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Next"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.images; _ck(_v, 4, 0, currVal_0); }, null); }
export function View_CarouselComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "app-carousel", [], null, null, null, View_CarouselComponent_0, RenderType_CarouselComponent)), i1.ɵprd(512, null, i3.Ng2DeviceService, i3.Ng2DeviceService, []), i1.ɵdid(2, 114688, null, 0, i4.CarouselComponent, [i1.PLATFORM_ID, i3.Ng2DeviceService], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
var CarouselComponentNgFactory = i1.ɵccf("app-carousel", i4.CarouselComponent, View_CarouselComponent_Host_0, {}, {}, []);
export { CarouselComponentNgFactory as CarouselComponentNgFactory };
