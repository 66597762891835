import { Component, OnInit, PLATFORM_ID, Inject, Renderer, ElementRef } from '@angular/core';
import {isPlatformBrowser, isPlatformServer} from '@angular/common';
import { Ng2DeviceService } from 'ng2-device-detector';
import * as $ from 'jquery';
import {environment} from '../../../../_config/environments/environment';

@Component({
  selector: 'app-home',
  templateUrl: './templates/home.component.html',
  styleUrls: ['./templates/home.component.css'],
  providers: [Ng2DeviceService]
})
export class HomeComponent implements OnInit {

  deviceInfo = null;

  constructor(@Inject(PLATFORM_ID) private platformId: Object,
    private deviceService: Ng2DeviceService, private renderer: Renderer, private elem: ElementRef) {
  }

  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      // console.log('Calling fixCardHeightToMaximumCardText...');
      this.fixCardHeightToMaximumCardText();
      if (this.deviceService.isDesktop()) {
        // console.log('HomeComponent desktop rendering');
      } else {
        this.removeHoverFromFlipContainer();
        // console.log('HomeComponent mobile rendering');
      }
    }
    // Server only code.
    if (isPlatformServer(this.platformId)) {
      console.log('HomeComponent server side rendering');
      console.log(environment.url);
    }
  }

  removeHoverFromFlipContainer() {
    // console.log('removeHoverFromFlipContainer() => Removing hover on flip-container');
    $('.row').click(function () {
      $(this).find('.flip-card').addClass('hover').mouseleave(function () {
        $(this).removeClass('hover');
        // console.log('removeHoverFromFlipContainer() => Removed hover on flip-container');
      });
    });
  }

  fixCardHeightToMaximumCardText() {
    const cardElements = this.elem.nativeElement.querySelectorAll('.card');
    const cardElementsHeights = Array.from(cardElements).map(x => (x as HTMLElement).getBoundingClientRect().height);
    const maxHeight = cardElementsHeights.reduce((prev, curr) => curr > prev ? curr : prev, 0);
    Array.from(cardElements)
      .forEach((x: HTMLElement) => {
        const closetFlipCard = x.closest('.flip-card');
        const closetCardBody = x.querySelector('.card-body');
        const closetCardText = x.querySelector('.card-text');

        x.setAttribute('style', 'height:' + `${maxHeight}px`);
        closetFlipCard.setAttribute('style', 'height:' + `${maxHeight}px`);
        closetCardBody.setAttribute('style', 'height:' + `${maxHeight}px`);
        closetCardText.setAttribute('style', 'height:' + `${maxHeight}px`);

        // console.log(`fixTextHeightInCards() => setting height for " + ${closetFlipCard.className} :  ${maxHeight}`);
      });
  }
}
