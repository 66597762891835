import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-services-sdl-tridion-sites',
  templateUrl: './templates/translation-search-integration.component.html',
  styleUrls: ['./templates/translation-search-integration.component.css']
})
export class TranslationSearchIntegrationComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }
}
