<app-carousel></app-carousel>
<div class="container">
  <div class="row">
    <div class="col-md-12">
      <h1 class="heading-title">
        <i class="fas fa-info-circle"></i> who we are
      </h1>
      <div class="sectionbody">
        <p>
          <strong>
            Tiger Systems, Inc — specializes in Software Application Development,
            Content Management Systems consulting & implementations, and Staffing services.
          </strong>
        </p>
        <p>
          We provide expert services for Software Solution Architecture, User Interaction Design, Digital Content Strategy,
          Search and Portal Integrations, Search Engine Optimization, E-commerce, Localized International Sites,
          Translation Platforms, and Staffing Services.
        </p>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <h1 class="heading-title"><i class="fa fa-cogs"></i> Our Services</h1>
    </div>
  </div>
  <div class="row">
    <div class="col-md-4 col-sm-6">
      <h4 class="card-title">
        Development
      </h4>
      <hr />
      <p class="card-text">
        <i class="fas fa-cloud"></i> We deliver best of breed Web Application development to fuel your business growth.<br /><br />
        <i class="fas fa-mobile-alt"></i> We can assist you with all your Mobile Application Development across multiple platforms.<br /><br />
        <i class="fas fa-pencil-ruler"></i> Our design team delivers UI designs customized to meet your marketing plans.
        <!--        <a href="/services/development" class="btn btn-link btn-sm">read more...</a>-->
      </p>
    </div>
    <div class="col-md-4 col-sm-6">
      <h4 class="card-title">
        SDL Tridion Sites
      </h4>
      <hr />
      <p class="card-text">
        <img src="/content/en-global/images/sdl-orange-logo.png" style="height:15px;" />
        We provide SDL expert services for Digital Content Strategy, Templating, GUI Extensions, Workflow, Event System,
        Search Integration, Translations, and Content Migrations. <br /><br />
        We implement Content Delivery solutions with DXA, DD4T, CWA, SCD, and custom MVC solutions.<br /><br />
        We support SDL infrastructure implementations in on-premises and cloud-hosted enviornments.
        <!--        <a href="/services/development" class="btn btn-link btn-sm">read more...</a>-->
      </p>
    </div>
    <div class="col-md-4 col-sm-6">
      <h4 class="card-title"> Content Management Systems</h4>
      <hr />
      <p class="card-text">
        <i class="far fa-edit"></i> We provide consulting and implemenation services for Content Management Systems, such as
        SDL Tridion Sites, Adobe Experience, Sitecore, Umbraco, Word Press, and Joomla. <br /><br />
        We bring extensive knowledge and resources for performing content migrations and translations
        between different Content Management Systems and languages.
        <!--        <a href="/services/development" class="btn btn-link btn-sm">read more...</a>-->
      </p>
    </div>
  </div>
  <br class="br-responsive"><br class="br-responsive">
  <div class="row">
    <div class="col-md-4 col-sm-6">
      <h4 class="card-title">
        Translation Platforms
      </h4>
      <hr />
      <p class="card-text">
        <i class="fa fa-language"></i> We provide expert services for implementing translation platforms,
        such as Google Translation API, SDL World Server, SDL Translation Management System, and custom translations
        solutions. <br /><br />
        We design custom solutions overcoming geographical challenges supporting multiple dialects and
        regions across the globe.
        <!--        <a href="/services/development" class="btn btn-link btn-sm">read more...</a>-->
      </p>
    </div>
    <div class="col-md-4 col-sm-6">
      <h4 class="card-title">
        Search Integrations
      </h4>
      <hr />
      <p class="card-text">
        <i class="fas fa-search"></i>"How about just a search box in the page header" — sounds familiar? <br /><br />
        That's how it starts and can morph into something awesome that works across sites!<br /><br />
        We develop custom search solutions with Azure, Elastic, and Solr  providing search capabilities across
        internal and public facing sites.
        <!--        <a href="/services/development" class="btn btn-link btn-sm">read more...</a>-->
      </p>
    </div>
    <div class="col-md-4 col-sm-6">
      <h4 class="card-title">IT & Staffing Services</h4>
      <hr />
      <p class="card-text">
        <i class="fa fa-server"></i> Our IT team provides round the clock support for on-premises and cloud-hosted
        infrastructure ensuring your business applications are always up and running smoothly&ndash;like a well-oiled machine.<br /><br />
        <i class="fas fa-users"></i> We provide on-demand staffing services customized to your business demands for a contract, part-time,
        and full-time employment.
        <!--        <a href="/services/development" class="btn btn-link btn-sm">read more...</a>-->
      </p>
    </div>
  </div>
</div>



