import { NgModule } from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { Ng2DeviceDetectorModule } from 'ng2-device-detector';

import { routes } from './app.routes';
import { AppComponent } from './app.component';

import { AboutComponent } from '../../../modules/tigsys/components/about/about.component';
import { ContactComponent } from '../../../modules/tigsys/components/contact/contact.component';
import { HomeComponent } from '../../../modules/tigsys/components/home/home.component';
import { ServicesComponent } from '../../../modules/tigsys/components/services/services.component';

import { CarouselComponent } from '../../../modules/_shared/components/carousel/carousel.component';
import { FooterComponent } from '../../../modules/_shared/components/footer/footer.component';
import { HeaderComponent } from '../../../modules/_shared/components/header/header.component';
import { NavbarComponent } from '../../../modules/_shared/components/navbar/navbar.component';

import { DevelopmentComponent } from '../../../modules/tigsys/components/services/development/development.component';
import { ContentManagementComponent } from '../../../modules/tigsys/components/services/content-management/content-management.component';
import { TranslationSearchIntegrationComponent } from '../../../modules/tigsys/components/services/translation-search-integration/translation-search-integration.component';

import { DataProviderService } from '../../../modules/_shared/services/data-provider.service';
import { MailProviderService } from '../../../modules/_shared/services/mail-provider.service';


@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    CarouselComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    AboutComponent,
    DevelopmentComponent,
    ContentManagementComponent,
    TranslationSearchIntegrationComponent,
    ContactComponent,
    ServicesComponent
  ],
    imports: [
        BrowserModule.withServerTransition({appId: 'tigsys'}),
        routes,
        Ng2DeviceDetectorModule.forRoot(),
        FormsModule,
        HttpClientModule,
        ReactiveFormsModule
    ],
  providers: [Title, MailProviderService, DataProviderService],
  bootstrap: []
})
export class AppStartModule {
}
