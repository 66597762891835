import { Component } from '@angular/core';

@Component({
  moduleId: module.id,
  selector: 'app-navbar',
  templateUrl: './templates/navbar.component.html',
  styleUrls: ['./templates/navbar.component.css']
})
export class NavbarComponent {
  logo = './content/en-global/images/header/navbar/logo-v11.svg';
}
