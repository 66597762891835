<!--<app-carousel></app-carousel>-->
<div class="container">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <h1 class="heading-title"><i class="fa fa-cogs"></i> Our Services</h1>
      </div>
    </div>
    <div class="row">
      <div class="col-md-4 col-sm-6">
        <div class="card-body">
          <h4 class="card-title">
            <i class="fas fa-cloud"></i> Web Applications
          </h4>
          <hr />
          <p class="card-text">
            We deliver best of breed Web Application development to meet our client's growing business
            needs using .NET, Java, PHP, Angular, React and other mainstream web technology platforms.
            We support hosted and on-premises infrastructure running your web applications.
            <!-- <a href="/services/web-development" class="btn btn-link btn-sm">read more...</a>-->
          </p>
        </div>
      </div>
      <div class="col-md-4 col-sm-6">
        <div class="card-body">
          <h4 class="card-title"><i class="fas fa-mobile-alt"></i> Mobile Applications</h4>
          <hr />
          <p class="card-text">
            We can assist you with all your Mobile Application Development. We deliver a custom mobile experience for your iOS,
            Android, and cross-platform devices, connecting your customers anytime from anywhere in the world, supporting
            scalability for future growth.
<!--            <a href="/services/mobile-development" class="btn btn-link btn-sm">read more...</a>-->
          </p>
        </div>
      </div>
      <div class="col-md-4 col-sm-6">
        <div class="card-body">
          <h4 class="card-title"><i class="fas fa-pencil-ruler"></i> User Interaction Design</h4>
          <hr />
          <p class="card-text">
            Our design team works closely with the business stakeholders to deliver UI designs customized to meet your marketing plans.
            We deliver designs to meet your current business requirements however, easily customizable for future business requirements.
<!--            <a href="/services/user-interaction-design" class="btn btn-link btn-sm">read more...</a>-->
          </p>
        </div>
      </div>
      <div class="col-md-4 col-sm-6">
        <div class="card-body">
          <h4 class="card-title"><i class="far fa-edit"></i> Content Management</h4>
          <hr />
          <p class="card-text">
            We provide services for open source and enterprise Content Management Systems (CMS),
            such as SDL Tridion Sites, Adobe Experience, Sitecore, Umbraco, Word Press, and Joomla.<br /><br />
            We bring vast expertise to integrate search servers (Solr, Elastic, Azure) providing search capabilities across portals.<br /> <br />
            We have extensive knowledge and resources for performing content migrations across CMS, platforms, and languages.
<!--            <a href="/services/content-management-systems" class="btn btn-link btn-sm">read more...</a>-->
          </p>
        </div>
      </div>
      <div class="col-md-4 col-sm-6">
        <div class="card-body">
          <h4 class="card-title">
            <img src="/content/en-global/images/sdl-orange-logo.png" style="height:22px;" /> SDL Tridion Sites
          </h4>
          <hr />
          <p class="card-text">
            We provide SDL expert services for Digital Content Strategy, Search Integration, Translations,
            implementations such as Templating, GUI Extensions, Workflow, Event System, and
            Content Delivery solutions with DXA, DD4T, CWA, SCD, and custom MVC in .NET or JAVA.<br /><br />
            We support content migrations with languages translation services, new installations, and existing infrastructure of SDL
            Tridion Sites in on-premises and cloud-hosted environments.
<!--            <a href="/services/sdl-tridion-sites" class="btn btn-link btn-sm">read more...</a>-->
          </p>
        </div>
      </div>
      <div class="col-md-4 col-sm-6">
        <div class="card-body">
          <h4 class="card-title"><i class="fas fa-language"></i> Translation Platforms</h4>
          <hr />
          <p class="card-text">
            We possess expert knowledge in implementing and integrating translation platforms such as Google Translation API, SDL World Server,
            SDL Translation Management System, and custom translations applications to meet your business needs<br /><br />
            We developed many custom translation applications supporting multiple languages while overcoming challenges for geographical differences across
            countries and continents.
<!--            <a href="/services/translation-platforms" class="btn btn-link btn-sm">read more...</a>-->
          </p>
        </div>
      </div>
      <div class="col-md-4 col-sm-6">
        <div class="card-body">
          <h4 class="card-title"><i class="fas fa-search"></i> Search Integration</h4>
          <hr />
          <p class="card-text">
            "How about just a search box in the page header" — sounds familiar? That's how it starts and morph into
            something awesome that works across sites! We can develop custom solutions using Push or crawl,
            Secured, Faceted, Server and Client solutions.<br /><br />

            Let's discuss the custom search for your business solutions. We integrate
            Solr, Elastic, Azure, and custom search engines with your existing or new applications.
<!--            <a href="/services/translation-platforms" class="btn btn-link btn-sm">read more...</a>-->
          </p>
        </div>
      </div>
      <div class="col-md-4 col-sm-6">
        <div class="card-body">
          <h4 class="card-title"><i class="fa fa-server"></i> IT Services</h4>
          <hr />
          <p class="card-text">
            Our IT team can provide round the clock support for on-premises and cloud-hosted
            infrastructure ensuring your business applications are always up and running smoothly&ndash;like a well-oiled machine.<br /><br />

            We provide customized IT Solutions which easily fit into your business processes. Our IT Solutions are designed
            for seamless connectivity between business applications providing reliable and secure access to business users.
<!--            <a href="/services/it-services" class="btn btn-link btn-sm">read more...</a>-->
          </p>
        </div>
      </div>
      <div class="col-md-4 col-sm-6">
        <div class="card-body">
          <h4 class="card-title"><i class="fas fa-users"></i> Staffing Services</h4>
          <hr />
          <p class="card-text">
            We can provide on-demand staffing services customized to your business demands for a contract, part-time,
            and full-time employment.<br /><br />
            Our staffing processes ensure we find candidates possessing the right skill sets to perform the required job.
            Our multi-level interview process effectively helps matching candidates according to their skill sets giving
            them a higher success rate and stress-free work environment.
<!--            <a href="/services/staffing-services" class="btn btn-link btn-sm">read more...</a>-->
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
