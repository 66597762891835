<div class="container contactContainerMargin">
  <div class="row">
    <div class="col-md-12">
      <h1 class="heading-title"><i class="fa fa-envelope"></i> Send Us Your Queries</h1><br/>
      <div class="row">
      <div class="col-md-9">
      <form id="reused-form" [formGroup]="contactForm" novalidate>
        <div class="card">
          <div class="card-header">Contact Form</div>
          <div class="card-body">
            <div class="form-group">
              <label for="name">Your Name</label>
              <input type="text" class="form-control" id="name" name="name" placeholder="Enter Your Name" formControlName="Name">
            </div>
            <div *ngIf="contactForm.controls['Name'].invalid && (contactForm.controls['Name'].dirty || contactForm.controls['Name'].touched)" class="alert alert-danger">
              <div *ngIf="contactForm.controls['Name'].errors?.required">
                Name is required.
              </div>
            </div>
            <div class="form-group">
              <label for="email">Email address</label>
              <div class="input-group">
                <div class="input-group-addon"><i class="fa fa-envelope-o"></i></div>
                <input type="email" class="form-control" id="email" placeholder="Enter Your Email" name="email" formControlName="Email">
              </div>
              <div *ngIf="contactForm.controls['Email'].invalid && (contactForm.controls['Email'].dirty || contactForm.controls['Email'].touched)" class="alert alert-danger">
                <div *ngIf="contactForm.controls['Email'].errors?.required">
                  Email is required.
                </div>
                <div *ngIf="contactForm.controls['Email'].errors?.pattern">
                  Email is invalid.
                </div>
              </div>
            </div>
            <div class="form-group">
              <label for="phone">Phone</label>
              <div class="input-group">
                <div class="input-group-addon"><i class="fa fa-phone"></i></div>
                <input type="tel" class="form-control" id="phone" placeholder="Enter Your Phone" name="phone" formControlName="Phone">
              </div>
              <!--<div *ngIf="contactForm.controls['Phone'].invalid && (contactForm.controls['Phone'].dirty || contactForm.controls['Phone'].touched)" class="alert alert-danger">
                <div *ngIf="contactForm.controls['Phone'].errors?.required">
                  Phone is required.
                </div>
                <div *ngIf="contactForm.controls['Phone'].errors?.pattern">
                  Phone is invalid.
                </div>
              </div>-->
            </div>
            <div class="form-group">
              <label for="message">Your Query</label>
              <textarea class="form-control" id="message" name="message" formControlName="Message"></textarea>
            </div>
            <div *ngIf="contactForm.controls['Message'].invalid && (contactForm.controls['Message'].dirty || contactForm.controls['Message'].touched)" class="alert alert-danger">
              <div *ngIf="contactForm.controls['Message'].errors?.required">
                Message is required.
              </div>
            </div>
            <button type="submit" [disabled]="contactForm.pristine || contactForm.invalid" #sendMailButton id="sendMailButton" (click)="sendMail()" class="btn btn-outline-primary">Submit</button>
            <div #sendingMessage id="sendingMessage" style="width:100%; height:100%; color:green; display:none; ">
              <br><h6>Sending email...</h6>
            </div>
            <div #successMessage id="successMessage" style="width:100%; height:100%; color:green; display:none; ">
              <h6>Your message was sent successfully.</h6>
            </div>
            <div #errorMessage id="errorMessage" style="width:100%; height:100%; color:red; display:none; ">
              <br>
              <h6>Sorry there was an error sending your form. Please try again or send direct email to: <a href="mailto:info@tigsys.com">info@tigsys.com</a></h6>
            </div>
          </div>
        </div>
      </form>
      </div>
      <div class="col-md-3">
        <ul class="list-group">
          <li class="list-group-item active">Contact Us</li>
          <li class="list-group-item">
            <p><i class="fa fa-home"></i>
              &nbsp;1684 Decoto Rd.<br>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              Suite 325 <br>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              Union City, CA 94587<br>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              USA
            </p>
            <p><i class="fa fa-phone"></i> &nbsp;+1 (415) 967-0052</p>
            <p><i class="fa fa-envelope"></i> &nbsp;<a href="mailto:info@tigsys.com">info@tigsys.com</a></p>
<!--            <ul class="list-inline">-->
<!--              <li class="list-inline-item"><a [ngClass]="{footerSocialIcon: true}" href="{{facebookLink}}" target="_blank"><i-->
<!--                class="fa fa-facebook-square"></i></a></li>-->
<!--              <li class="list-inline-item"><a [ngClass]="{footerSocialIcon: true}" href="{{linkedInLink}}" target="_blank"><i-->
<!--                class="fa fa-linkedin-square"></i></a></li>-->
<!--              <li class="list-inline-item"><a [ngClass]="{footerSocialIcon: true}" href="{{twitterLink}}" target="_blank"><i-->
<!--                class="fa fa-twitter-square"></i></a></li>-->
<!--            </ul>-->
          </li>
        </ul>
      </div>
      </div>
    </div>
  </div>
</div>

