<div class="container about-container-margin">
  <div class="row">
    <div class="col-md-9">
      <h1 class="heading-title"><i class="fa fa-exclamation-circle"></i> {{about_title}}</h1>
      <p>
      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi fringilla lorem nisi, et rutrum erat dignissim in.
      Quisque sollicitudin euismod erat sed iaculis. Donec iaculis gravida laoreet. Curabitur nec imperdiet sapien, eu
      aliquam purus. Suspendisse quis quam bibendum, tincidunt sem in, ornare nulla. Donec ut consequat nulla. Sed
      ornare
      vel tellus quis faucibus. Pellentesque ornare interdum lectus, sed efficitur leo ultricies non. Nulla facilisi.
      Nunc
      eu gravida nibh.
      Ut consequat, dui vestibulum malesuada convallis, nisi felis eleifend sapien, et tempus nisl odio ac leo. Nulla
      non
      nunc sed neque sollicitudin malesuada. Aenean eget nibh sed nisl ultricies porttitor eget eget sem. Suspendisse
      quis
      urna luctus, tempus est ut, luctus eros. Nullam malesuada, nulla quis commodo dignissim, erat purus lobortis eros,
      nec
      eleifend elit felis in risus. Curabitur ante tortor, auctor vel egestas id, aliquam quis sem. Cras sodales
      placerat
      quam, nec sollicitudin nunc. Nulla cursus magna arcu, id tempor arcu ullamcorper nec. Praesent non leo eget purus
      molestie fringilla. Nulla sit amet nisi et magna finibus congue. Ut placerat ullamcorper leo. Sed tellus turpis,
      vulputate non tristique a, ultricies in diam. Nulla mollis mollis lorem, vitae placerat turpis porta nec.
      Nulla at neque quis odio vehicula mollis quis vitae dolor. In rhoncus nec est ac suscipit. Curabitur ullamcorper
      suscipit neque egestas auctor. Aenean convallis vulputate diam nec vestibulum. Nulla sit amet tempus augue. Mauris
      a
      cursus ex. Proin fermentum, lectus dictum varius efficitur, nibh augue dignissim quam, ac mattis nulla risus nec
      arcu.
      Nam ac mauris et enim malesuada fringilla. Nulla aliquam sapien mi, non hendrerit diam varius ac. Aliquam
      sollicitudin, nibh ac fringilla condimentum, purus ipsum tristique orci, vel interdum velit purus sit amet lacus.
      Maecenas sed ullamcorper massa.
      Integer eu lorem ut quam tincidunt tempor. Nulla facilisi. Phasellus at turpis ut metus ornare mattis a ac arcu.
      Integer sed tortor consectetur, efficitur sem sed, bibendum odio. Pellentesque accumsan ultricies augue ac
      vulputate.
      Aliquam erat volutpat. Sed mauris ipsum, vestibulum eu pulvinar in, condimentum eget diam. Nam in feugiat metus.
      Vivamus suscipit congue condimentum. Interdum et malesuada fames ac ante ipsum primis in faucibus. Integer in
      fringilla urna, in euismod nibh.
      Mauris vitae tortor sit amet risus mollis dapibus. Nam et feugiat velit, nec condimentum nulla. Mauris blandit leo
      ac
      vehicula sollicitudin. Praesent rutrum convallis orci ac sodales. Vestibulum cursus viverra turpis quis interdum.
      Nullam sit amet egestas massa. Suspendisse elementum, dui quis finibus tincidunt, arcu enim viverra risus, et
      hendrerit quam risus nec nulla. Integer ornare elementum lacus in consectetur. Sed vitae porttitor lectus, eu
      lobortis
      nisi. Etiam lacinia scelerisque turpis, ac feugiat lectus eleifend eu. Sed dictum scelerisque risus ut blandit.
      Pellentesque nec volutpat lacus, eu finibus enim. Curabitur semper eu turpis non sagittis.</p>
    </div>
    <div class="col-md-3">
      <ul class="list-group">
        <li class="list-group-item active">Featured Links</li>
        <li class="list-group-item">Link 1</li>
        <li class="list-group-item">Link 1</li>
        <li class="list-group-item">Link 1</li>
        <li class="list-group-item">Link 1</li>
        <li class="list-group-item">Link 1</li>
        <li class="list-group-item">Link 1</li>

      </ul>
    </div>
  </div>
</div>
