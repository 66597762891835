import {Injectable, OnInit} from '@angular/core';
import {environment} from '../../../_config/environments/environment';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {EmailModel} from '../../models/email.model';
import {observable, Observable} from 'rxjs';


@Injectable()
export class MailProviderService implements OnInit {
  headers: HttpHeaders;

  constructor(private http: HttpClient) {
    this.headers = new HttpHeaders();
    this.headers.set('Content-Type', 'application/json');
  }

  ngOnInit() { }

  sendEmail(model: EmailModel): Observable<any> {
    const apiUrl = environment.sendMailUrl;
    console.log('Mail apiUrl: ', apiUrl);
    model.ContactEmail = environment.contactEmail;
    model.From = environment.fromEmail;
    return new Observable<any>( observable => {
      this.http.post(apiUrl, model, {headers: this.headers}).subscribe(
        (val) => {
          console.log('POST call successful value returned in body', val);
          observable.next(val);
        },
        response => {
          console.log('POST call in error', response)
          observable.error(response);
        },
        () => {
          console.log('The POST observable is now completed.');
          observable.complete();
          observable.unsubscribe();
        });
    });
  }
}
