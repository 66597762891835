import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-about',
  templateUrl: './templates/about.component.html',
  styleUrls: ['./templates/about.component.css']
})
export class AboutComponent implements OnInit {
  about_title = 'About Tiger Systems';
  constructor() {
  }

  ngOnInit() {
  }

}
