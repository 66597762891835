/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../modules/_shared/components/header/header.component.ngfactory";
import * as i2 from "../../../modules/_shared/components/header/header.component";
import * as i3 from "@angular/router";
import * as i4 from "../../../modules/_shared/components/footer/footer.component.ngfactory";
import * as i5 from "../../../modules/_shared/components/footer/footer.component";
import * as i6 from "./app.component";
import * as i7 from "@angular/platform-browser";
var styles_AppComponent = [];
var RenderType_AppComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AppComponent, data: {} });
export { RenderType_AppComponent as RenderType_AppComponent };
export function View_AppComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-header", [], null, null, null, i1.View_HeaderComponent_0, i1.RenderType_HeaderComponent)), i0.ɵdid(1, 114688, null, 0, i2.HeaderComponent, [], null, null), (_l()(), i0.ɵeld(2, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i0.ɵdid(3, 212992, null, 0, i3.RouterOutlet, [i3.ChildrenOutletContexts, i0.ViewContainerRef, i0.ComponentFactoryResolver, [8, null], i0.ChangeDetectorRef], null, null), (_l()(), i0.ɵeld(4, 0, null, null, 1, "app-footer", [], null, null, null, i4.View_FooterComponent_0, i4.RenderType_FooterComponent)), i0.ɵdid(5, 114688, null, 0, i5.FooterComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); _ck(_v, 3, 0); _ck(_v, 5, 0); }, null); }
export function View_AppComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-root", [], null, null, null, View_AppComponent_0, RenderType_AppComponent)), i0.ɵdid(1, 49152, null, 0, i6.AppComponent, [i7.Title], null, null)], null, null); }
var AppComponentNgFactory = i0.ɵccf("app-root", i6.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };
