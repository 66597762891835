<div class="container carousel-top-margin">
  <div id="carouselIndicators" class="carousel slide" data-ride="carousel">
   <!-- <ol class="carousel-indicators">
      <li data-target="#carouselIndicators" data-slide-to="0" class="active"></li>
      <li data-target="#carouselIndicators" data-slide-to="1"></li>
      <li data-target="#carouselIndicators" data-slide-to="2"></li>
      <li data-target="#carouselIndicators" data-slide-to="3"></li>
      <li data-target="#carouselIndicators" data-slide-to="4"></li>
      <li data-target="#carouselIndicators" data-slide-to="5"></li>
      <li data-target="#carouselIndicators" data-slide-to="6"></li>
    </ol>-->
    <div class="carousel-inner" role="listbox">
      <div *ngFor="let image of images" class="carousel-item {{image.cls}}">
        <img class="d-block img-fluid" src="{{image.name}}" alt="{{image.title}}">
        <div class="carousel-caption d-none d-md-block">
          <h3>{{image.captionTitle}}</h3>
          <p>{{image.captionText}} <br>
             {{image.captionTextLineTwo}}
          </p>
        </div>
      </div>
    </div>
    <a class="carousel-control-prev" href="#carouselIndicators" role="button" data-slide="prev">
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="sr-only">Previous</span>
    </a>
    <a class="carousel-control-next" href="#carouselIndicators" role="button" data-slide="next">
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="sr-only">Next</span>
    </a>
  </div>
</div>
