import {Component, OnInit, AfterViewInit, ElementRef, ViewChild, Inject, PLATFORM_ID, Renderer} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {EmailModel} from '../../../models/email.model';
import {MailProviderService} from '../../../_shared/services/mail-provider.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {isPlatformServer} from '@angular/common';

@Component({
  selector: 'app-contact',
  templateUrl: './templates/contact.component.html',
  styleUrls: ['./templates/contact.component.css']
})

export class ContactComponent implements OnInit, AfterViewInit {
  @ViewChild('sendMailButton') sendMailButton: ElementRef;
  @ViewChild('sendingMessage') sendingMessage: ElementRef;
  @ViewChild('errorMessage') errorMessage: ElementRef;
  @ViewChild('successMessage') successMessage: ElementRef;

  facebookLink: String = 'https://www.facebook.com/';
  twitterLink: String = 'https://www.twitter.com';
  linkedInLink: String = 'https://www.linkedin.com';

  formData: EmailModel = new EmailModel();
  contactForm: FormGroup;
  deviceInfo = null;

  constructor(@Inject(PLATFORM_ID) private platformId: Object, private http: HttpClient,
              private mailProviderService: MailProviderService,
              private formBuilder: FormBuilder) {
    this.createForm();
  }

  ngOnInit() {
    // Server only code.
    if (isPlatformServer(this.platformId)) {
      console.log('ContactComponent server side rendering');
    }
  }

  ngAfterViewInit() {}

  createForm() {
    this.contactForm = this.formBuilder.group({
      Name: ['', Validators.required],
      Email: ['', [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$')]],
      Phone: [''],
      // Phone: ['', [Validators.nullValidator, Validators.pattern('[0-9 ]{10}')]],
      Message: ['', Validators.required]
    });
  }

  sendMail() {
    this.sendMailButton.nativeElement.disabled = true;
    this.sendingMessage.nativeElement.style.display = 'block';
    this.errorMessage.nativeElement.style.display = 'none';
    this.formData = this.contactForm.value;
    const sendMailSubscription = this.mailProviderService.sendEmail(this.formData).subscribe(
      (val) => {
        // console.log('this.mailProviderService.sendEmail call successful value returned in body: ', val);
        this.successMessage.nativeElement.style.display = 'block';
        this.sendMailButton.nativeElement.style.display = 'none';
        this.sendingMessage.nativeElement.style.display = 'none';
      },
      response => {
        // console.log('this.mailProviderService.sendEmail call error ', response);
        this.errorMessage.nativeElement.innerHtml += `<h4>${response.error.message}</h4>`;
        this.errorMessage.nativeElement.style.display = 'block';
        this.sendMailButton.nativeElement.disabled = false;
        this.sendingMessage.nativeElement.style.display = 'none';
      },
      () => {
        // console.log('The this.mailProviderService.sendEmail call observable is now completed.');
        sendMailSubscription.unsubscribe();
      });
  }
}
