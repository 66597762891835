import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-development',
  templateUrl: './templates/development.component.html',
  styleUrls: ['./templates/development.component.css']
})
export class DevelopmentComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }
}
