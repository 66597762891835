import {Component, ElementRef, Inject, OnInit, PLATFORM_ID, Renderer} from '@angular/core';
import {isPlatformBrowser, isPlatformServer} from '@angular/common';
import { Ng2DeviceService } from 'ng2-device-detector';

@Component({
  selector: 'app-services',
  templateUrl: './templates/services.component.html',
  styleUrls: ['./templates/services.component.css']
})
export class ServicesComponent implements OnInit {

  deviceInfo = null;

  constructor(@Inject(PLATFORM_ID) private platformId: Object,
              private deviceService: Ng2DeviceService, private renderer: Renderer, private elem: ElementRef) {
  }

  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      // console.log('Calling fixCardHeightToMaximumCardText...');
      this.fixCardHeightToMaximumCardText();
      if (this.deviceService.isDesktop()) {
        // console.log('ServicesComponent desktop rendering');
      } else {
        // console.log('ServicesComponent mobile rendering');
      }
    }

    // Server only code.
    if (isPlatformServer(this.platformId)) {
      console.log('ServicesComponent server side rendering');
    }
  }

  fixCardHeightToMaximumCardText() {
    const cardElements = this.elem.nativeElement.querySelectorAll('.card');
    const cardElementsHeights = Array.from(cardElements).map(x => (x as HTMLElement).getBoundingClientRect().height);
    const maxHeight = cardElementsHeights.reduce((prev, curr) => curr > prev ? curr : prev, 0);
    Array.from(cardElements)
      .forEach((x: HTMLElement) => {
        const closetCardBody = x.querySelector('.card-body');
        const closetCardText = x.querySelector('.card-text');

        x.setAttribute('style', 'height:' + `${maxHeight}px`);
        closetCardBody.setAttribute('style', 'height:' + `${maxHeight}px`);
        closetCardText.setAttribute('style', 'height:' + `${maxHeight}px`);

        // console.log(`fixTextHeightInCards() => setting height for " + ${closetCardBody.className} :  ${maxHeight}`);
      });
  }
}
