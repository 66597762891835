<div class="container service-container-margin">
  <div class="row">
    <div class="col-md-12">
      <div class="flip-card">
        <div class="flip-card-inner">
          <div class="flip-card-front">
            <h4 class="card-title">
              Development
            </h4>
            <i class="fas fa-cloud faCustomStyles"></i><br />
            <i class="fas fa-mobile-alt faCustomStyles"></i><br />
            <i class="fas fa-pencil-ruler faCustomStyles"></i>
          </div>
          <div class="flip-card-back">
            <div class="card">
              <div class="card-body">
                <p class="card-text">
                  We deliver best of breed Web Application development to meet our client's growing business
                  needs using .NET, Java, PHP, Angular, React and other mainstream web technology platforms.
                  We support hosted and on-premises infrastructure running your web applications.
                  <br />
                  We can assist you with all your Mobile Application Development. We deliver a custom mobile experience for your iOS,
                  Android, and cross-platform devices, connecting your customers anytime from anywhere in the world, supporting
                  scalability for future growth. <br />
                  Our design team works closely with the business stakeholders to deliver UI designs customized to meet your business marketing plans.
                  We deliver designs according to current business requirements, however easily customizable for future business requirements.
                  <a href="/services/development" class="btn btn-link btn-sm">read more...</a>
                </p>
              </div>
            </div>
          </div>

          <!--<div class="flip-card">
            <div class="flip-card-inner">
              <div class="flip-card-front">
                <i class="fas fa-mobile-alt faCustomStyles"></i>
                <h4 class="card-title">Mobile Applications</h4>
              </div>
              <div class="flip-card-back">
                <div class="card">
                  <div class="card-body">
                    <p class="card-text">
                      We can assist you with all your Mobile Application Development. We deliver a custom mobile experience for your iOS,
                      Android, and cross-platform devices, connecting your customers anytime from anywhere in the world, supporting
                      scalability for future growth.
                      <a href="/services/mobile-development" class="btn btn-link btn-sm">read more...</a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>-->
          <!--<div class="flip-card">
            <div class="flip-card-inner">
              <div class="flip-card-front">
                <i class="fas fa-pencil-ruler faCustomStyles"></i>
                <h4 class="card-title">User Interaction Design</h4>
              </div>
              <div class="flip-card-back">
                <div class="card">
                  <div class="card-body">
                    <p class="card-text">
                      Our design team works closely with the business stakeholders to deliver UI designs customized to meet your business marketing plans.
                      We deliver designs according to current business requirements, however easily customizable for future business requirements.
                      <a href="/services/user-interaction-design" class="btn btn-link btn-sm">read more...</a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>-->
        </div>
      </div>
    </div>
  </div>
</div>
