import {PLATFORM_ID, Component, OnInit, Inject} from '@angular/core';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { Ng2DeviceService } from 'ng2-device-detector';

@Component({
  selector: 'app-carousel',
  templateUrl: './templates/carousel.component.html',
  styleUrls: ['./templates/carousel.component.css'],
  providers: [Ng2DeviceService]
})

export class CarouselComponent implements OnInit {
  images: Object;
  desktopImages: Object;
  mobileImages: Object;
  deviceInfo = null;

  constructor( @Inject(PLATFORM_ID) private platformId: Object,
               private deviceService: Ng2DeviceService) {
    this.desktopImages = [
      { name: './content/en-global/images/carousel/v9/01-TigerWillPlan-v9.jpg', title: '', cls: 'active',
        captionTitle: 'Business Solutions', captionText: 'supporting your business strategy'  },
      { name: './content/en-global/images/carousel/v9/02-TigerWillDesign-v9.jpg', title: '', cls: '',
        captionTitle: 'Business Brand', captionText: 'creating your business identity' },
      { name: './content/en-global/images/carousel/v9/03-TigerWillCreate-v9.jpg', title: '', cls: '',
        captionTitle: 'Online Presence', captionText: 'with seo & social media'},
      { name: './content/en-global/images/carousel/v9/04-TigerWillDevelop-v9.jpg', title: '', cls: '',
        captionTitle: 'Software Solutions', captionText: 'fueling your business growth'   },
      { name: './content/en-global/images/carousel/v9/05-TigerWillImplement-v9.jpg', title: '', cls: '',
        captionTitle: 'IT Infrastructure', captionText: 'supporting your business expansion'  },
      { name: './content/en-global/images/carousel/v9/06-TigerWillDeliver-v9.jpg', title: '', cls: '',
        captionTitle: 'Best Customer Service', captionText: 'keeping your business running smoothly'},
      { name: './content/en-global/images/carousel/v9/07-TigerWillHunt-v9.jpg', title: '', cls: '',
        captionTitle: 'Technology Problems', captionText: 'supporting your global operations' }
    ];

    this.mobileImages = [
      { name: './content/en-global/images/carousel/v3m/01-TigerWillPlan-v3m.jpg', title: '', cls: 'active',
        captionTitle: 'Business Solutions', captionText: 'supporting your business strategy'  },
      { name: './content/en-global/images/carousel/v3m/02-TigerWillDesign-v3m.jpg', title: '', cls: '',
        captionTitle: 'Business Brand', captionText: 'creating your business identity' },
      { name: './content/en-global/images/carousel/v3m/03-TigerWillCreate-v3m.jpg', title: '', cls: '',
        captionTitle: 'Online Presence', captionText: 'with seo & social media'},
      { name: './content/en-global/images/carousel/v3m/04-TigerWillDevelop-v3m.jpg', title: '', cls: '',
        captionTitle: 'Software Solutions', captionText: 'fueling your business growth'   },
      { name: './content/en-global/images/carousel/v3m/05-TigerWillImplement-v3m.jpg', title: '', cls: '',
        captionTitle: 'IT Infrastructure', captionText: 'supporting your business expansion'  },
      { name: './content/en-global/images/carousel/v3m/06-TigerWillDeliver-v3m.jpg', title: '', cls: '',
        captionTitle: 'Best Customer Service', captionText: 'keeping your business running smoothly'},
      { name: './content/en-global/images/carousel/v3m/07-TigerWillHunt-v3m.jpg', title: '', cls: '',
        captionTitle: 'Technology Problems', captionText: 'supporting your global operations' }
    ];
  }
  ngOnInit() {

    if (isPlatformBrowser(this.platformId)) {
      // Client only code.
      // console.log('CarouselComponent client side rendering');
      if (this.deviceService.isDesktop()) {
        this.images = this.desktopImages;
      } else {
        this.images = this.mobileImages;
      }
      this.epicFunction();
    }

    // Server only code.
    if (isPlatformServer(this.platformId)) {
      console.log('CarouselComponent server side rendering');
    }
  }
  epicFunction() {
    this.deviceInfo = this.deviceService.getDeviceInfo();
    // console.log(this.deviceInfo);
  }
}
