import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './templates/footer.component.html',
  styleUrls: ['./templates/footer.component.css']
})
export class FooterComponent implements OnInit {
  facebookLink: String = 'https://www.facebook.com/';
  twitterLink: String = 'https://www.twitter.com';
  linkedInLink: String = 'https://www.linkedin.com';
  constructor() { }

  ngOnInit() {
  }

}
