<nav class="navbar navbar-expand-lg navbar-light bg-white fixed-top">
  <div class="container">
    <a class="navbar-brand" routerLink="home"><img src="{{logo}}" class="logo"/></a>
    <button class="navbar-toggler" type="button" data-toggle="collapse"
            data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarNav">
      <ul class="navbar-nav">
        <li class="nav-item">
          <a class="nav-link" routerLink="home" routerLinkActive="active"><i class="fa fa-home"></i> Home</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="services" routerLinkActive="active"><i class="fa fa-cogs"></i> Services</a>
        </li>
        <!--<li class="nav-item">-->
          <!--<a class="nav-link" routerLink="about" routerLinkActive="active"><i class="fa fa-question"></i> About</a>-->
        <!--</li>-->
        <li class="nav-item">
          <a class="nav-link" routerLink="contact" routerLinkActive="active"><i class="fa fa-envelope"></i> Contact</a>
        </li>
      </ul>
    </div>
  </div>
</nav>

